import actionsHelper from 'utils/actionsHelper';
const names: any = [
  'EMS_GET_PROPERTY_META',
];


const createActions: any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}
