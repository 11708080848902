import zh_TW from 'locales/basic/zh_TW.json';
const languages: any = {
  zh_TW: {
    locale: 'zh_TW',
    key: 'zh',
    label: '\u7E41\u9AD4\u4E2D\u6587',
    id: 0,
    messages: zh_TW,
  },
};
export default languages;
