import actionsHelper from 'utils/actionsHelper';
const names: any = [
  'EMS_GET_SETTLEMENT_LIST',
  'EMS_DELETE_SETTLEMENT',
  'EMS_GET_SETTLEMENT_DETAIL',
  'EMS_GET_ELECTRICITY_BILL',
  'EMS_CREATE_SETTLEMENT',
];


const createActions: any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}
