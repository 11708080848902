import { put, call, delay } from "redux-saga/effects";
import History from 'utils/history';
import GlobalActions from '../stores/global/actions';

export default function* errorHandlerSaga(error:any) {
  const errorCode = error.status;
  const errorKey = error.data?.errors[0].message;

  if(error.status == 500 || error.status == 404) {
    if (error.hasOwnProperty('useDialog') && error.useDialog) {
      yield put(GlobalActions.toogleDialog(error.dialogSetting.error))
    } else {
      if (error.status === 404) {
        yield put(GlobalActions.snackbarRequest({
          visible: true,
          variant: 'error',
          message: `error.message.${error.data?.errors[0].message.split(' ').join('')}`,
        }));
      } else {
        yield put(GlobalActions.snackbarRequest({
          visible: true,
          variant: 'warning',
          message: 'notice.system.warning'
        }));
      }
    }
  } else if(error.status == 401){
    
    let json:any = localStorage.getItem('account-auth');
    const accountAuth:any = JSON.parse(json) || {};

    if(accountAuth.token) {
      localStorage.removeItem('account-auth');
      yield put(GlobalActions.toogleDialog({
        visible: true,
        title: 'caption.system.warning',
        content: 'description.logout.warning',
        icon: {color: '#ffab40', name: 'error'},
        confirmText: 'action.confirm',
        confirmAction: {pathname: '/user/login'}
      }));
    } else {
      return error;      
    }
    
  } else if (errorCode === 403 && errorKey === 'notAuthorized') {
    yield put(GlobalActions.snackbarRequest({
      visible: true,
      variant: 'error',
      message: 'notice.error.permission.denied',
    }));
  } else if (errorCode === 403 && errorKey === 'accountIsSuspended') {
    localStorage.removeItem('account-auth');
    History.push('/user/exception');
  } else if (errorCode === 403 && errorKey === 'insufficientPrivileges') {
    yield put(GlobalActions.snackbarRequest({
      visible: true,
      variant: 'error',
      message: 'notice.error.insufficient.privileges',
    }));
  } else {
    return error;
  }
}