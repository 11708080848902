import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {Button, Box} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import languages from 'locales';
import GlobalActions from '../stores/global/actions';

interface ImenuItem {
  label: string,
  locale: string,
  id: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      fontSize: 14
    }
  })
)

 function LanguageMenu(props:any) {
   const {onChange} = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const global = useSelector( (state:any) => state.global);
  
  const selectedIndex = languages[global.locale].id;
  const open = Boolean(anchorEl);
  const dispatch = useDispatch()

  const onChangeLanguage = (action: any): any => dispatch(GlobalActions.changeLanguage(action))

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function onMenuClose() {
    setAnchorEl(null);
  }

  function onSelectionchange(event:any, item: ImenuItem) {
    onChangeLanguage({locale: item.locale});
    if(typeof onChange === 'function') {
      onChange(item.locale)
    }
    onMenuClose();
  }

  return (
    <Box {...props}>
      <Button fullWidth style={{fontSize: 14, color: props.color || '#000', justifyContent: 'space-between'}} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
        <span >{languages[global.locale].label}</span> <Icon style={{fontSize: 14}}>arrow_drop_down</Icon>
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={onMenuClose}
        TransitionComponent={Fade}
      >
        {
          Object.keys(languages).map((key:any, index:any) => {
            const item:any = languages[key];
            return (
              <MenuItem
              className={classes.menuItem}
              key={index}
              disabled={index === selectedIndex}
              selected={index === selectedIndex}
              onClick={event => onSelectionchange(event, item)}
            >
              {item.label}
            </MenuItem>
            )
          })
        }
      </Menu>
    </Box>
  );
}

export default LanguageMenu