import React, {useEffect} from 'react';
import { 
  Typography,
  Box,
  Paper,
  Tooltip,
  Link,
 } from '@material-ui/core';
import WalletAddressDialog from 'pages/account/components/WalletAddress';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import LanguageMenu from 'components/LanguageMenu';
import GlobalActions from 'stores/global/actions';
import AccountActions from 'stores/account/store/actions';

const ProfileInfo = (props:any) => {
  const {
    intl,
  } = props;
  const dispatch = useDispatch();
  const sharedData = useSelector((state:any) => state.global.sharedData);
  const userInfo = sharedData.user.data;

  return (
    <>
      <Paper>
        <Box px={4} py={2} borderBottom="#e9e9e9 1px solid">
          <Typography variant="subtitle1" component="h3"><FormattedMessage id="caption.account.profile"/></Typography>
        </Box>
        <Box p={3} px={4}>
          <Box display="flex" pb={2}>
            <Typography variant="body2"><FormattedMessage id="label.account.info"/></Typography>
            <Box pl={1}>{`: ${userInfo.email || ''}`}</Box>
          </Box>
          <Box display="flex" pb={2} alignItems="center">
            <Typography variant="body2"><FormattedMessage id="label.language"/></Typography>
            
            <Box ml={1}  display="flex" alignItems="center">
              :<LanguageMenu onChange={(locale:any) => {
                dispatch(AccountActions.updateMeProfileRequest({language: locale}))
              }} ml={1} border="solid 1px rgba(0, 0, 0, 0.15)" borderRadius={4} minWidth={200}/>
            </Box>
          </Box>
          {
            userInfo.current == 'investor' &&
          
          <Box display="flex" pb={2} alignItems="center" data-testid="walletAddressBlock">
            <Typography variant="body2"><FormattedMessage id="label.wallet.address"/></Typography>
            <Box  ml={1}>
              <Tooltip placement="top" title={userInfo.note || ''}><Typography variant="body2">: {userInfo.note && userInfo.note.substring(0,5) + '...' + userInfo.note.substring(userInfo.note.length - 5,userInfo.note.length)|| ''}</Typography></Tooltip>
            </Box>
            <Box ml={1}>
              <Link data-testid="editWalletBtn" href='' onClick={(e:any) => {
                e.preventDefault();
                dispatch(GlobalActions.toogleDialog({
                  visible: true,
                  title: userInfo.note ? 'label.modify.wallet.address' : 'label.add.wallet.address',
                  component: <WalletAddressDialog {...props} walletAddress={userInfo.note}/>
                }))
              }}><FormattedMessage id={userInfo.note ? 'action.modify' : 'action.add'}/></Link>
            </Box>
          </Box>
        }
        </Box>
      </Paper>
    </>
  )
}

export default injectIntl(ProfileInfo);