import actionsHelper from 'utils/actionsHelper';

const names:any = [
  'GET_PROPERTY_REVENUE_LIST',
  'GET_PROPERTY_ID_REVENUE_LIST',
  'GET_EXPORT_REVENUE_DATA',
];

const  createActions:any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}