import initialState from './initialState';
import Actions from './actions';

export default function global(state = initialState, action: any) {
  switch (action.type) {
    case Actions.CHANGE_LANGUAGE:
      return {
        ...state,
        locale: action.payload.locale,
        languageSettings: {
          isInitial: true
        }
      }
    case Actions.SET_TOOGLE_DIALOG:
      let globalDialogSettings: any = { ...state.globalDialog };

      if(action.payload.visible) {
        globalDialogSettings = {
          confirmBol: false,
          close: false,
          confirmAction: '',
          cancelAction: '',
          title: '',
          content: '',
          iconTitle: '',
          icon: '',
          loading: false,
          component: null,
          confirmText: 'action.confirm',
          cancelText: 'action.cancel',
          contentHasPadding: true,
        }
      }
        
      globalDialogSettings = {
        ...globalDialogSettings,
        ...action.payload
      }
      return {
        ...state,
        globalDialog: globalDialogSettings
      }
    case Actions.SET_SNACKBAR:
      let snackbarSettings:any = {...state.snackbar}
          
      if(action.payload.visible) {
        snackbarSettings = {
          ...snackbarSettings,
          message: '...',
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {},
          actionComponent: null,
        }
      }
      snackbarSettings = {
        ...snackbarSettings,
        ...action.payload
      }

      return {
        ...state,
        snackbar: snackbarSettings
      }
    case Actions.SET_BREADCRUMB_NAME: 
      return {
        ...state,
        breadcrumbName: {
          ...state.breadcrumbName,
          ...action.payload
        }
      }
    case Actions.POST_UPLOAD_FILE_REQUEST: 
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: true,
          loaded: false,
          data: {}
        }
      }
    case Actions.POST_UPLOAD_FILE_SUCCESS: 
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: false,
          loaded: true,
          data: action.payload
        }
      }
    case Actions.POST_UPLOAD_FILE_ERROR: 
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          loading: false,
          loaded: false,
          data: {}
        }
      }
    case Actions.LOG_OUT:
      return {
        ...state,
        sharedData: {
          ...state.sharedData,
          user: {
            ...state.sharedData.user,
            loaded: false,
            loading: false,
            data: {
              current: 'default'
            },
          }
        }
      }
    case Actions.SET_SOCKET_DATA:
      return {
        ...state,
        sharedData: {
          ...state.sharedData,
          socket: {
            ...state.sharedData.socket,
            [action.payload.module]: {
              ...state.sharedData.socket[action.payload.module],
              [action.payload.event]: action.payload.data
            }
          }
        }
      }
  
    case Actions.GET_USER_INFO_REQUEST:
      return {
        ...state,
        sharedData: {
          ...state.sharedData,
          user: {
            ...state.sharedData.user,
            loading: true,
            loaded: false,
            updated: false,
          }
        }
      }
      case Actions.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        sharedData: {
          ...state.sharedData,
          user: {
            ...state.sharedData.user,
            loading: false,
            loaded: true,
            updated: false,
            data: {
              ...state.sharedData.user.data,
              ...action.payload
            }
          }
        }
      }
      case Actions.GET_USER_INFO_ERROR:
      return {
        ...state,
        sharedData: {
          ...state.sharedData,
          user: {
            ...state.sharedData.user,
            loading: false,
            loaded: false,
            updated: false,
            data: {}
          }
        }
      }

      case Actions.CHANGE_USER_IDENTITY:
          const json:any = localStorage.getItem('account-auth');
          const accountAuth:any = JSON.parse(json) || {};
          accountAuth.current = action.payload.current;
          localStorage.setItem('account-auth', JSON.stringify(accountAuth));
          
        return {
          ...state,
          sharedData: {
            ...state.sharedData,
            user: {
              ...state.sharedData.user,
              data: {
                ...state.sharedData.user.data,
                current: action.payload.current
              }
            }
          }
          
        }
      case Actions.GET_CAPTCHA_REQUEST:
        return {
          ...state,
          captcha: {
            loading: true,
            loaded: false,
            data: {}
          }
        }
      case Actions.GET_CAPTCHA_SUCCESS:
        return {
          ...state,
          captcha: {
            loading: false,
            loaded: true,
            data: action.payload
          }
        }
      case Actions.GET_CAPTCHA_ERROR:
        return {
          ...state,
          captcha: {
            loading: false,
            loaded: false,
            data: {}
          }
        }

      case Actions.CHANGE_TRADER_LEVEL_REQUEST:
      return {
        ...state,
        sharedData: {
          ...state.sharedData,
          user: {
            ...state.sharedData.user,
            loading: true,
            loaded: false,
            updated: false,
          }
        }
      }
      case Actions.CHANGE_TRADER_LEVEL_SUCCESS:
      return {
        ...state,
        sharedData: {
          ...state.sharedData,
          user: {
            ...state.sharedData.user,
            loading: false,
            loaded: true,
            updated: true,
          }
        }
      }
      case Actions.CHANGE_TRADER_LEVEL_ERROR:
      return {
        ...state,
        sharedData: {
          ...state.sharedData,
          user: {
            ...state.sharedData.user,
            loading: false,
            loaded: false,
            updated: false,
          }
        }
      }
      case Actions.SET_MODULE_ACL:
      return {
        ...state,
        moduleAcl: action.payload
      }
      case Actions.GET_META_REQUEST:
        return {
          ...state,
          meta: {
            loading: true,
            loaded: false,
            data: {},
          }
        }
      case Actions.GET_META_SUCCESS:
        return {
          ...state,
          meta: {
            loading: false,
            loaded: true,
            data: action.payload,
          }
        }
      case Actions.GET_META_ERROR:
        return {
          ...state,
          meta: {
            loading: false,
            loaded: false,
            data: {},
          }
        }
      case Actions.SET_FEATURE_ACL:
        return {
          ...state,
          featureAcl: action.payload
        }
      case Actions.DELETE_USER_ACCESS_TOKEN_REQUEST:
        return {
          ...state,
          deleteUserAccessToken: {
            loading: true,
            loaded: false,
          }
        }
      case Actions.DELETE_USER_ACCESS_TOKEN_SUCCESS:
        return {
          ...state,
          deleteUserAccessToken: {
            loading: false,
            loaded: true,
          }
        }
      case Actions.DELETE_USER_ACCESS_TOKEN_ERROR:
        return {
          ...state,
          deleteUserAccessToken: {
            loading: false,
            loaded: false,
          }
        }
      case Actions.START_UP_REQUEST:
          return {
            ...state,
            startUp: {
              loading: true,
              loaded: false,
            }
          }
        case Actions.START_UP_SUCCESS:
          return {
            ...state,
            startUp: {
              loading: false,
              loaded: true,
            }
          }
        case Actions.START_UP_ERROR:
          return {
            ...state,
            startUp: {
              loading: false,
              loaded: true,
            }
          }
    default:
      break;
  }
  return state
}