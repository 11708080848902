import actionsHelper from 'utils/actionsHelper';
const names: any = [
  'EMS_GET_DASHBOARD_PROPERTY_LIST',
  'EMS_GET_CONSUMPTION_HISTORY',
  'EMS_UPDATE_MAIN_METER',
  'EMS_GET_DEMAND_DATA',
  'EMS_GET_ABNORMAL_EVENT_LIST',
];


const createActions: any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}
