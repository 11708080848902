import actionsHelper from 'utils/actionsHelper';

const names:any = [
  'GET_RECEIPT_LIST',
  'CREATE_RECEIPT',
  'GET_RECEIPT_DATA'
];

const  createActions:any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}