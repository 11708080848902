import React, { useEffect } from 'react';
import Loadable from 'react-loadable';
import { useDispatch } from 'react-redux';
import RoutesConfig from './router/';
import GlobalActions from 'stores/global/actions';
import { Route, Switch, Prompt } from "react-router-dom";
import { useSelector } from 'react-redux';

import {
  MuiThemeProvider
} from '@material-ui/core/styles';
import {
  CssBaseline,
  LinearProgress
} from '@material-ui/core';

import DefaultTheme from './themes';

const basicRouter: any = RoutesConfig.find((route: any) => route.path == '/');
const firstRouter: any = basicRouter.routes.filter((route: any) => route.path !== '/404' && route.path !== '/account')[0];

const layoutsLoadable: any = {}
RoutesConfig.map((route: any, index: any) => {
  layoutsLoadable[route.component] = Loadable({
    loader: () => import(`layouts/${route.component.replace('layouts/', '')}`),
    loading: () => <LinearProgress />
  });
});
const pluginsLoadable: any = {}
RoutesConfig.map((route: any, index: any) => {
  var path = (route.path === '/') ? 'basic' : route.path.substr(1);
  if (path != 'basic') {
    pluginsLoadable[route.path] = require(`plugins/${path}`);
    return;
  }

  route.routes.map((route2: any, index2: any) => {
    var path2 = route2.path.substr(1);
    if (path2 == '404') {
      return;
    }
    pluginsLoadable[route2.path] = require(`plugins/${path2}`);
  })
});

const Routes = (props: any) => {
  return (
    <>
      {
        RoutesConfig.map((route: any, index: any) => {
          const path: any = route.path.replace('/', '');
          const paths = RoutesConfig.filter((route: any) => route.path != '/').map((route: any) => route.path);
          return <Route key={index} exact={path ? false : paths.filter((value: any) => !!props.location.pathname.match(value)).length > 0} path={route.path} render={props => {
            const RouteComponent: any = layoutsLoadable[route.component];
            return (
              <RouteComponent {...props} routes={basicRouter.isProject ? firstRouter.routes : route.routes} redirect={route.redirect || ''} />
            )
          }} />
        })
      }
    </>
  )
}

const App = (props: any) => {
  const dispatch = useDispatch();
  const startUp = useSelector((state:any) => state.global.startUp);
  useEffect(() => {
    dispatch(GlobalActions.startUpRequest())
  }, []);

  return (
    <MuiThemeProvider theme={DefaultTheme}>
      <div className="App">
        <CssBaseline />
        {
          Object.keys(pluginsLoadable).map((item) => {
            if (pluginsLoadable[item].default.methods) {
              return pluginsLoadable[item].default.methods.map((plugin: any) => {
                return React.createElement(plugin)
              })
            }
          })
        }
        <Prompt message={(location: any) => {
          if (location.pathname.indexOf('http') >= 0) {
            window.open(location.pathname.slice(location.pathname.indexOf('http')));
          }
          return location.pathname.indexOf('http') < 0;
        }} />
        {
          startUp.loaded &&
          <Switch>
            <Routes />
          </Switch>
        }
        
      </div>
    </MuiThemeProvider>
  )
}

export default App;
