export const GET_RENTAL_LIST_REQUEST: string = 'GET_RENTAL_LIST_REQUEST';
export const GET_RENTAL_LIST_SUCCESS: string = 'GET_RENTAL_LIST_SUCCESS';
export const GET_RENTAL_LIST_ERROR: string = 'GET_RENTAL_LIST_ERROR';

const getRentalListRequest = (payload: any) => ({
  type: GET_RENTAL_LIST_REQUEST,
  payload
});

const getRentalListSuccess = (payload: any) => ({
  type: GET_RENTAL_LIST_SUCCESS,
  payload
});

const getRentalListError = (error: any) => ({
  type: GET_RENTAL_LIST_ERROR,
  error
});

export default {
  GET_RENTAL_LIST_REQUEST,
  GET_RENTAL_LIST_SUCCESS,
  GET_RENTAL_LIST_ERROR,
  getRentalListRequest,
  getRentalListSuccess,
  getRentalListError,
}