export default [
  {
    path: '/user',
    component: 'layouts/full',
    isRoot: true,
    routes: [
      {
        title: '登入',
        path: '/user/login',
        component: 'user/Login',
      },
      {
        title: '註冊',
        path: '/user/signup',
        component: 'user/Signup',
      },
      {
        title: '忘記密碼',
        path: '/user/resetpassword',
        component: 'user/ResetPassword',
      },
      {
        title: 'E-mail驗證',
        path: '/user/emailverify',
        component: 'user/EmailVerify',
      },
      {
        title: '平台審核中',
        path: '/user/review',
        component: 'user/AccountReview',
      },
      {
        title: '帳號異常',
        path: '/user/exception',
        component: 'user/AccountException',
      },
    ],
  },
  {
    path: '/',
    component: 'layouts/drawerV2',
    redirect: {
      enterprise: '/account/settings',
      investor: '/account/settings',
    },
    routes: [
      {
        title: 'title.404.notfound',
        Icon: 'components/Icons/IconBlockchain',
        path: '/404',
        component: '404',
        order: 999,
        routes: [],
        navList: false,
        breadcrumb: false,
      },
      {
        title: 'title.property',
        Icon: 'domain',
        path: '/property',
        authorize: ['enterprise'],
        breadcrumbLink: false,
        order: 1,
        routes: [
          {
            title: 'title.property.list',
            path: '/property/list',
            component: 'property/list/Index',
            navList: true,
            authorize: ['enterprise'],
            routes: [
              {
                title: 'title.property.list.create',
                path: '/property/list/create',
                component: 'property/list/Create',
                routes: [],
              },
              {
                title: 'caption.property.detail',
                path: '/property/list/:propertyId/detail',
                component: 'property/list/Detail',
                routes: [],
              },
              {
                title: 'caption.property.detail',
                path: '/property/list/:propertyId/edit',
                component: 'property/list/Create',
                routes: [],
              },
              {
                title: 'title.property.list.unit.list',
                path: '/property/list/:propertyId/unitlist',
                component: 'property/list/UnitList',
                routes: [
                  {
                    title: 'title.create.lease',
                    path: '/property/list/:propertyId/unitlist/:unitId/createlease',
                    component: 'property/list/CreateLease',
                    routes: [],
                  },
                  {
                    title: 'title.unit.detail',
                    path: '/property/list/:propertyId/unitlist/:unitId',
                    component: 'property/list/UnitDetail',
                    routes: [
                      {
                        title: 'title.lease',
                        path: '/property/list/:propertyId/unitlist/:unitId/lease/:leaseId/edit',
                        component: 'property/list/CreateLease',
                        routes: [],
                      },
                      {
                        title: 'title.lease',
                        path: '/property/list/:propertyId/unitlist/:unitId/lease/:leaseId',
                        component: 'property/list/LeaseDetail',
                        routes: [],
                      },
                    ],
                  },
                ],
              },
              {
                title: 'title.create.unit',
                path: '/property/list/:propertyId/createunit',
                component: 'property/list/CreateUnit',
                routes: [],
              },
            ],
          },
          {
            title: 'title.property.rental.list',
            path: '/property/rental',
            component: 'property/rental/Index',
            navList: true,
            authorize: ['enterprise'],
            routes: [],
          },
          {
            title: 'title.bookkeeping',
            path: '/property/bookkeeping',
            component: 'property/bookkeeping/Index',
            navList: true,
            authorize: ['enterprise'],
            routes: [],
          },
          {
            title: 'title.property.bill.list',
            path: '/property/bill/list',
            component: 'property/bill/Index',
            navList: true,
            authorize: ['enterprise'],
            routes: [
              {
                title: 'title.bill.create',
                path: '/property/bill/list/create',
                component: 'property/bill/Create',
                routes: [],
              },
              {
                title: 'title.invoice.edit',
                path: '/property/bill/list/:invoiceId/edit',
                component: 'property/bill/Create',
                routes: [],
              },
              {
                title: 'title.invoice.detail',
                path: '/property/bill/list/:invoiceId/detail',
                component: 'property/bill/Detail',
                routes: [],
              },
              {
                title: 'title.bill.print',
                path: '/property/bill/list/print',
                component: 'property/bill/Print',
                routes: [],
              },
            ],
          },
          {
            title: 'title.receipt',
            path: '/property/receipt',
            component: 'property/receipt/Index',
            navList: true,
            authorize: ['enterprise'],
            routes: [
              {
                title: 'title.receipt.create',
                path: '/property/receipt/create',
                component: 'property/receipt/Create',
                routes: [],
              },
            ],
          },
          {
            title: 'title.revenue.list',
            path: '/property/revenue',
            component: 'property/revenue/Index',
            navList: true,
            authorize: ['enterprise'],
            routes: [
              {
                title: 'title.detail',
                path: '/property/revenue/:propertyId/list',
                component: 'property/revenue/PropertyList',
                routes: [],
              },
            ],
          },
        ],
      },
      {
        title: 'title.ems',
        Icon: 'IconElectricityManagement',
        path: '/ems',
        authorize: ['default', 'investor', 'enterprise'],
        breadcrumbLink: false,
        order: 2,
        routes: [
          {
            title: 'title.ems.overview',
            path: '/ems/overview/list',
            component: 'ems/overview/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [],
          },
          {
            title: 'Dashboard',
            path: '/ems/dashboard',
            component: 'ems/dashboard/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [],
          },
          {
            title: 'title.ems.2c791a4a-7dcc-4619-92f9-cd4349791d9d',
            path: '/ems/settlement',
            component: 'ems/settlement/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [
              {
                title: 'title.ems.d7733660-6ae1-404b-a4a5-ea80a512558e',
                path: '/ems/settlement/:settlementId/detail',
                component: 'ems/settlement/Detail',
                navList: false,
              },
              {
                title: 'title.ems.9c848945-7516-40b6-890b-6557dbcc5083',
                path: '/ems/settlement/create',
                component: 'ems/settlement/Create',
                navList: false,
              },
            ],
          },
          {
            title: 'title.ems.db8c6caf-1376-4dd0-951b-20e0eaf9cca3',
            path: '/ems/report',
            component: 'ems/report/Index',
            navList: true,
            authorize: ['default', 'investor', 'enterprise'],
          },
        ],
      },
      {
        title: 'title.account',
        Icon: 'AccountCircleOutlinedIcon',
        path: '/account',
        redirect: '/account/settings',
        breadcrumbLink: false,
        order: 999,
        routes: [
          {
            title: 'title.account.settings',
            path: '/account/settings',
            component: 'PluginsPage',
            plugins: [
              {
                component: 'account/ProfileInfo',
              },
              {
                component: 'account/Security',
              },
            ],
          },
        ],
      },
    ],
  },
];
