import React, { useEffect } from 'react';
import { 
  Typography,
  FormControl,
  TextField,
  Box,
  Button,
  CircularProgress
 } from '@material-ui/core';
 import { useDispatch, useSelector } from 'react-redux';
import {FormattedMessage , injectIntl} from 'react-intl';
import Vaildator from 'utils/validator';
import GlobalActions from 'stores/global/actions';
import AccountActions from 'stores/account/store/actions';

const AddWallet = (props:any) => {
  const { intl } = props;
  const dispatch = useDispatch();
  const userStore = useSelector((state:any) => state.users);
  const [errorMessage, setErrorMessage] = React.useState<any>({});
  const [formData, setFormData] = React.useState<any>({
    walletAddress: ''
  });
    
  const validations:any = {
    walletAddress: [Vaildator.required, Vaildator.isWalletAddress]
  };
  const validationField:any = ['walletAddress'];

  function onInputChange(e:any) {
    const name = e.target.name;
    const value = e.target.value;
    let newFormData:any = formData;
    newFormData[name] = value;

    if (validations[name]) {
      const validate  = Vaildator.onValidate({validations: validations[name], name, value});
      let newErrorMessage:any = { ...errorMessage, ...validate.errorMessage}
      

      setErrorMessage(newErrorMessage);
      setFormData(newFormData)
    } else {
      setFormData(newFormData)
    }
    
  }

  const onSubmit = () => {
    let validBol:any = true;
    validationField.forEach((key:string) => {
      if (validBol && validations[key]) {
        const validate  = Vaildator.onValidate({validations: validations[key], name: key, value: formData[key]});
        let newErrorMessage:any = { ...errorMessage, ...validate.errorMessage}
        setErrorMessage(newErrorMessage);
        validBol = validate.validBol
      }
    });

    if (validBol) {

      dispatch(AccountActions.updateMeProfileRequest({note: formData.walletAddress}))
    }
  }

  useEffect(() => {
    if(props.walletAddress) {
      setFormData({walletAddress: props.walletAddress})
    }
  },[props.walletAddress])

  useEffect(() => {
    if(!userStore.editBasicInfo.loading && userStore.editBasicInfo.loaded && formData.walletAddress) {
      dispatch(GlobalActions.snackbarRequest({
        visible: true,
        variant: 'success',
        message: props.walletAddress ? 'snackbar.success.modify.wallet' : 'snackbar.success.add.wallet'
      }));
      dispatch(GlobalActions.toogleDialog({visible:false}))
    }
    
  }, [userStore.editBasicInfo.loaded])

  return (
    <>
      <Box>
        <Typography variant="body1" gutterBottom><FormattedMessage id={'placeholder.ethereum.wallet.adddress'}/></Typography>
        <FormControl fullWidth>
          <TextField
            id="outlined-dense"
            name="walletAddress"
            value={formData.walletAddress}
            label={`* ${intl.formatMessage({id: 'label.ethereum.wallet.address'})}`}
            variant="outlined"
            margin="dense"
            onChange={onInputChange}
            error={ !!errorMessage['walletAddress'] }
            helperText = { errorMessage['walletAddress'] ? intl.formatMessage({id: `${errorMessage['walletAddress']}`}) : ''}
          />
        </FormControl>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={5}>
        <Button data-testid="cancelBtn" disabled={ userStore.editBasicInfo.loading} style={{marginLeft: 8}} variant="outlined" onClick={() =>  dispatch(GlobalActions.toogleDialog({visible:false})) }>
          <FormattedMessage id="action.cancel"/>
        </Button>
        <Button data-testid="submitBtn" disabled={ userStore.editBasicInfo.loading} style={{marginLeft: 8}} variant="contained" color="primary" onClick={onSubmit}>
          {
            userStore.editBasicInfo.loading ? <CircularProgress size={24}/> : <FormattedMessage id="action.save"/>
          }
        </Button>
      </Box>
    </>
  )
}

export default injectIntl(AddWallet);