import { put, call, select, delay, take } from "redux-saga/effects";
import GlobalActions from 'stores/global/actions';
import History from 'utils/history';
import errorHandlerSaga from './errorHandlerSaga';
import {
  uploadFile,
  getCaptcha,
  getMeta,
  deleteUserAccessToken,
} from 'services/defaultApi';
import RoutesConfig from '../router/';
import BasicConfig from 'config/basic/config.json';
const enableMetaAPI: any = BasicConfig.basic.enableMetaAPI.value;

export function* getCaptchaSaga() {
  try {
    let res: any = yield call(getCaptcha);
    yield put(GlobalActions.getCaptchaSuccess({...res}));
  } catch(error) {
    yield put(GlobalActions.getCaptchaError(error));
    const errorHandler = yield errorHandlerSaga(error);
  }
}

export function* changeLanguageSaga(action:any) {
  try {
    const sharedData = yield select(state => state.global.sharedData);
    yield put(GlobalActions.changeLanguage({...action.payload}));
  } catch(e) {

  }
}

export function* toogleDialogSaga(action:any) {
  try {
    yield put(GlobalActions.setToogleDialog({...action.payload}));
  } catch(e) {

  }
}

export function* snackbarSaga(action:any) {
  try {
    yield put(GlobalActions.setSnackbar({...action.payload}));
  } catch(e) {

  }
}

export function* uploadFileSaga(payload: any) {
  try {
    let res:Promise<any> = yield call(uploadFile,payload);
    yield put(GlobalActions.uploadFileSuccess(res));
  } catch(error) {
    const errorHandler = yield errorHandlerSaga(error);
    yield put(GlobalActions.uploadFileError(errorHandler));
  }
}

export function* logoutSaga() {
  // yield put(GlobalActions.toogleDialog({visible: false}));
  yield delay(10);
  localStorage.removeItem('account-auth');
  History.push('/');
}

export function* getMetaSaga() {
  try {
    let res: Promise<any> = yield call(getMeta);
    yield put(GlobalActions.getMetaSuccess(res));
  } catch (error) {
    const errorHandler = yield errorHandlerSaga(error);
    yield put(GlobalActions.getMetaError(errorHandler));
  }
}

export function* deleteUserAccessTokenSaga({payload}: any) {
  try {
    let res: any = yield call(deleteUserAccessToken, payload);
    yield put(GlobalActions.deleteUserAccessTokenSuccess(res));
  } catch(error) {
    yield put(GlobalActions.deleteUserAccessTokenError(error));
    const errorHandler = yield errorHandlerSaga(error);
  }
}

export function* startUpSaga() {
  try {
    const installedUserModule = RoutesConfig.map((route: any) => route.path).indexOf('/user') >= 0;

    if (installedUserModule) {
      try {
        const UserActions: any = require(`stores/${installedUserModule ? 'user/store': 'global'}/actions`);
        const { getUserInfoRequest, GET_USER_INFO_SUCCESS,GET_USER_INFO_ERROR } = UserActions.default;
        yield put(getUserInfoRequest());
        yield take([GET_USER_INFO_ERROR, GET_USER_INFO_SUCCESS])
        
      } catch (error) {
        console.log(error)
      }
    }
    if(enableMetaAPI) {
      yield put(GlobalActions.getMetaRequest());
      yield take([GlobalActions.GET_META_SUCCESS, GlobalActions.GET_META_ERROR])
    }
    yield put(GlobalActions.startUpSuccess());
  } catch(error) {
    yield put(GlobalActions.startUpError(error));
    const errorHandler = yield errorHandlerSaga(error);
  }
}