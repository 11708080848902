// import 'normalize.css';
import './assets/scss/App.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter,Router, Switch } from 'react-router-dom';
import History from './utils/history'
import { Provider } from 'react-redux';
import store from './stores'
import * as serviceWorker from './serviceWorker';

(async() => {
  let stores:any = await store();
  ReactDOM.render(
    <Provider store={stores}>
      <Router history ={History} >
        <App />
      </Router>
    </Provider>
  , document.getElementById('root')
  );
})()


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
