import { 
  createMuiTheme,
} from '@material-ui/core/styles';

import Config from './config.json';

const config:any = Config;
const themeConfig:any = config.default || {};

const MuiTheme = createMuiTheme({
    typography: {
      fontFamily: [
        'Roboto',
        'Noto Sans TC',
        'sans-serif'
      ].join(','),
      body1: {
        fontWeight: 500
      }
    },
    palette: {
      primary: {
        main: themeConfig.primary?.value || '#1890ff'
      },
      secondary: {
        main: themeConfig.secondary?.value || '#d54747'
      }
    },
    overrides: {
      MuiButton: {
        outlined: {
          background: '#fff',
          border: '1px solid #d9d9d9',
          color: 'rgba(0, 0, 0, 0.65)'
        },
      },
    }
  });

export default MuiTheme;