import actionsHelper from 'utils/actionsHelper';

const names:any = [
  'GET_PROPERTY_LIST',
  'GET_PROPERTY_DETAIL',
  'CREATE_PROPERTY_DETAIL',
  'PUT_PROPERTY_DETAIL',
  'DEL_PROPERTY_DETAIL',
  'GET_UNIT_LIST',
  'GET_UNIT',
  'POST_UNIT',
  'PUT_UNIT',
  'DELETE_UNIT',
  'PATCH_UNIT_STATUS',
  'GET_LEASE_LIST',
  'GET_UNIT_LEASE_LIST',
  'GET_PROPERTY_NAME_LIST',
  'CREATE_LEASE',
  'GET_LEASE_DETAIL',
  'UPDATE_LEASE_DETAIL',
  'DELETE_LEASE',
  'CHANGE_LEASE_STATUS',
  'CHANGE_TERMINATE_DATE'
];

const  createActions:any = actionsHelper(names);

export const SET_PROPERTY_DRAFT: string = 'SET_PROPERTY_DRAFT';
export const SET_LEASE_LIST_MENU: string = 'SET_LEASE_LIST_MENU';
export const SET_UNIT_ROWS: string = 'SET_UNIT_ROWS';
export const CLEAR_UNIT_ROWS: string = 'CLEAR_UNIT_ROWS';

const setPropertyDraft = (payload: any) => ({
  type: SET_PROPERTY_DRAFT,
  payload
});

const setUnitRows = (payload: any) => ({
  type: SET_UNIT_ROWS,
  payload
});

const clearUnitRows = (payload: any) => ({
  type: CLEAR_UNIT_ROWS,
  payload
});

const setLeaseListMenu = (payload: any) => ({
  type: SET_LEASE_LIST_MENU,
  payload
});


export default {
  SET_PROPERTY_DRAFT,
  setPropertyDraft,
  SET_UNIT_ROWS,
  setUnitRows,
  CLEAR_UNIT_ROWS,
  clearUnitRows,
  SET_LEASE_LIST_MENU,
  setLeaseListMenu,
  ...createActions.types,
  ...createActions.actions
}