import actionsHelper from 'utils/actionsHelper';

const names:any = [
  'GET_USER_INFO',
  'CHANGE_TRADER_LEVEL',
];

const  createActions:any = actionsHelper(names);

export const LOGIN_REQUEST:string = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS:string = 'LOGIN_SUCCESS';
export const LOGIN_ERROR:string = 'LOGIN_ERROR';

export const SIGNUP_REQUEST:string = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS:string = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR:string = 'SIGNUP_ERROR';

export const RESET_REQUEST:string = 'RESET_REQUEST';
export const RESET_SUCCESS:string = 'RESET_SUCCESS';
export const RESET_ERROR:string = 'RESET_ERROR';

export const RESET_CONFIRM_REQUEST:string = 'RESET_CONFIRM_REQUEST';
export const RESET_CONFIRM_SUCCESS:string = 'RESET_CONFIRM_SUCCESS';
export const RESET_CONFIRM_ERROR:string = 'RESET_CONFIRM_ERROR';

export const VERIFY_ACCOUNT_REQUEST:string = 'VERIFY_ACCOUNT_REQUEST';
export const VERIFY_ACCOUNT_SUCCESS:string = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_ERROR:string = 'VERIFY_ACCOUNT_ERROR';

export const VERIFY_RESEND_REQUEST:string = 'VERIFY_RESEND_REQUEST';
export const VERIFY_RESEND_SUCCESS:string = 'VERIFY_RESEND_SUCCESS';
export const VERIFY_RESEND_ERROR:string = 'VERIFY_RESEND_ERROR';

const loginRequest = (payload: any) => ({
  type: LOGIN_REQUEST,
  payload
});

const loginSuccess = (payload: any) => ({
  type: LOGIN_SUCCESS,
  payload
});

const loginError = (error: any) => ({
  type: LOGIN_ERROR,
  error
});

const signupRequest = (payload: any) => ({
  type: SIGNUP_REQUEST,
  payload
});

const signupSuccess = (payload: any) => ({
  type: SIGNUP_SUCCESS,
  payload
});

const signupError = (error: any) => ({
  type: SIGNUP_ERROR,
  error
});

const resetRequest = (payload: any) => ({
  type: RESET_REQUEST,
  payload
});

const resetSuccess = (payload: any) => ({
  type: RESET_SUCCESS,
  payload
});

const resetError = (error: any) => ({
  type: RESET_ERROR,
  error
});

const resetConfirmRequest = (payload: any) => ({
  type: RESET_CONFIRM_REQUEST,
  payload
});

const resetConfirmSuccess = (payload: any) => ({
  type: RESET_CONFIRM_SUCCESS,
  payload
});

const resetConfirmError = (error: any) => ({
  type: RESET_CONFIRM_ERROR,
  error
});

const verifyAccountRequest = (payload: any) => ({
  type: VERIFY_ACCOUNT_REQUEST,
  payload
});

const verifyAccountSuccess = (payload: any) => ({
  type: VERIFY_ACCOUNT_SUCCESS,
  payload
});

const verifyAccountError = (error: any) => ({
  type: VERIFY_ACCOUNT_ERROR,
  error
});

const verifyResendRequest = (payload: any) => ({
  type: VERIFY_RESEND_REQUEST,
  payload
});

const verifyResendSuccess = (payload: any) => ({
  type: VERIFY_RESEND_SUCCESS,
  payload
});

const verifyResendError = (error: any) => ({
  type: VERIFY_RESEND_ERROR,
  error
});

export default {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  loginRequest,
  loginSuccess,
  loginError,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  signupRequest,
  signupSuccess,
  signupError,

  RESET_REQUEST,
  RESET_SUCCESS,
  RESET_ERROR,
  resetRequest,
  resetSuccess,
  resetError,
  RESET_CONFIRM_REQUEST,
  RESET_CONFIRM_SUCCESS,
  RESET_CONFIRM_ERROR,
  resetConfirmRequest,
  resetConfirmSuccess,
  resetConfirmError,

  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_ERROR,
  verifyAccountRequest,
  verifyAccountSuccess,
  verifyAccountError,
  VERIFY_RESEND_REQUEST,
  VERIFY_RESEND_SUCCESS,
  VERIFY_RESEND_ERROR,
  verifyResendRequest,
  verifyResendSuccess,
  verifyResendError,
  ...createActions.types,
  ...createActions.actions
}