import request from 'utils/request.ts';
const axios:any = new request('global').request;

export async function uploadFile(payload:any):Promise<any> {
  return axios.post(`/v1/file`, payload);
}

export async function getCaptcha():Promise<any> {
  return axios.post(`/v1/captcha`);
}

export async function getMeta(): Promise<any> {
  return axios.get('/v1/meta');
}

export async function deleteUserAccessToken(payload: any):Promise<any> {
  return axios.delete(`/v1/tokens/${payload.token}`, payload);
}