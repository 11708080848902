import actionsHelper from 'utils/actionsHelper';
const names: any = [
  'EMS_GET_PROPERTY_LIST',
  'EMS_GET_SPACE_LIST',
  'EMS_GET_DIALOG_SPACE_LIST',
  'EMS_GET_ELECTRIC_METER_LIST',
  'EMS_SWITCH_ELECTRIC_METER_STATE',
  'EMS_GET_ELECTRIC_METER_MAIN_METER_LIST',
  'EMS_UPDATE_ELECTRIC_METER',
  'EMS_GET_ELECTRIC_METER_GROUP_LIST',
  'EMS_GET_GROUP_ELECTRIC_METER_LIST',
  'EMS_GET_NEW_ELECTRIC_METER_LIST',
];


const createActions: any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}
