import actionsHelper from 'utils/actionsHelper';

const names:any = [
  'POST_CREATE_JOURNALS',
  'GET_RECENT_UPDATES_LIST'
];

const  createActions:any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}