import actionsHelper from 'utils/actionsHelper';

const names:any = [
  'GET_INVOICE_LIST',
  'GET_INVOICE_DATA',
  'UPDATE_INVOICE_DATA',
  'DELETE_INVOICES',
  'PATCH_INVOICES'
];

const  createActions:any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}