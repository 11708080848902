import React, {useEffect} from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import { 
  withStyles
} from '@material-ui/core/styles';
import { 
  CircularProgress,
  Button,
  Box,
  FormControl,
  TextField
 } from '@material-ui/core';
 import Jssha from 'jssha';
 import Vaildator from 'utils/validator';
 import AccountActions from 'stores/account/store/actions';
 import GlobalActions from 'stores/global/actions';

const EditPassword = (props:any) => {
  const dispatch = useDispatch();
  const {
    intl,
    dialogConfig,
  } = props;

  const [validations, setValidations] = React.useState<any>({
    oldPassword: [Vaildator.required],
    newPassword: [Vaildator.required, Vaildator.passwordRegex],
    confirm: [Vaildator.required]
  });
  const [formData, setFormData] = React.useState<any>({
    oldPassword: '',
    newPassword: '',
    confirm: ''
  });
  const validationField: string[] = ['oldPassword', 'newPassword','confirm'];
  const [errorMessage, setErrorMessage] =  React.useState<any>({});
  const users = useSelector((state:any) => state.account.store);

  function onInputChange(e:any) {
    const name:string = e.target.name;
    const value = e.target.value;
    let validationsNew = {...validations}
    setFormData({ ...formData, [name]: value});
    if (validations[name]) {
      if(name == 'confirm') {
        let confirmationPW = Vaildator.confirmationPW(formData.newPassword);
        validationsNew = {...validations, [name]: [Vaildator.required, confirmationPW]}
        setValidations({...validationsNew})
      }

      const validate  = Vaildator.onValidate({validations: validationsNew[name], name, value});
      setErrorMessage({...errorMessage,[name]: validate.errorMessage[name]})
    } 
  }

  function onSubmit() {

    let valid = true;
    validationField.forEach((key) => {
      if (valid && validations[key]) {
        const validate  = Vaildator.onValidate({validations: validations[key], name: key, value: formData[key]});
        setErrorMessage({...errorMessage,...validate.errorMessage})
        valid = validate.validBol;
        
      }
    });

    if (valid) {
      const password512:any = new Jssha('SHA3-512', 'TEXT');
      password512.update(formData.newPassword)
      const oldPassword512:any = new Jssha('SHA3-512', 'TEXT');
      oldPassword512.update(formData.oldPassword)

      dispatch(AccountActions.changePasswordRequest({password: password512.getHash('HEX'), oldPassword: oldPassword512.getHash('HEX')}))
    }
  }


  useEffect(() => {
    if(!users.changePassword.loading && users.changePassword.loaded && formData.newPassword) {
      dispatch(GlobalActions.snackbarRequest({ visible: true, variant: 'success', message: 'snackbar.success.password'}));
      dispatch(GlobalActions.toogleDialog({visible:false}))
    }
    
  }, [users.changePassword.loaded])

  return (
    <>
    <Box px={3} py={2} borderTop={dialogConfig.border.value ? 1 : 0} style={{borderColor: 'rgba(0, 0, 0, .25)'}}>
      <Box mb={2} width="100%">
        <FormControl fullWidth>
          <TextField
            id="outlined-dense"
            type="password"
            name="oldPassword"
            value={formData.oldPassword}
            label={ intl.formatMessage({id: `label.current.password`})}
            variant="outlined"
            margin="dense"
            placeholder={intl.formatMessage({id: 'placeholder.current.password'})}
            onChange={onInputChange}
            error={ !!errorMessage['oldPassword'] }
            helperText = { errorMessage['oldPassword'] ? intl.formatMessage({id: `${errorMessage['oldPassword']}`}) : ''}
          />
        </FormControl>
      </Box>
      <Box mb={2} width="100%">
        <FormControl fullWidth>
          <TextField
            id="outlined-dense"
            type="password"
            name="newPassword"
            value={formData.newPassword}
            label={ intl.formatMessage({id: `placeholder.password.signup`})}
            variant="outlined"
            margin="dense"
            placeholder={intl.formatMessage({id: 'placeholder.new.password'})}
            onChange={onInputChange}
            error={ !!errorMessage['newPassword'] }
            helperText = { errorMessage['newPassword'] ? intl.formatMessage({id: `${errorMessage['newPassword']}`}) : ''}
          />
        </FormControl>
      </Box>
      <Box mb={2} width="100%">
        <FormControl fullWidth error>
          <TextField
            id="outlined-dense"
            type="password"
            name="confirm"
            value={formData.confirm}
            label={ intl.formatMessage({id: `label.passwordConfirm`})}
            variant="outlined"
            margin="dense"
            onChange={onInputChange}
            error={ !!errorMessage['confirm'] }
            helperText = { errorMessage['confirm'] ? intl.formatMessage({id: `${errorMessage['confirm']}`}) : ''}
          />
        </FormControl>
      </Box>
    </Box>
    <Box px={3} py={2} display="flex" justifyContent="flex-end">
        <Button data-testid="cancelBtn" disabled={ users.changePassword.loading} style={{marginLeft: 8, width: '120px'}} variant="outlined" onClick={() =>  dispatch(GlobalActions.toogleDialog({visible:false})) }>
          <FormattedMessage id="action.cancel"/>
        </Button>
        <Button data-testid="submitBtn" disabled={ users.changePassword.loading} style={{marginLeft: 8, width: '120px'}} variant="contained" color="primary" onClick={onSubmit}>
          {
             users.changePassword.loading && <CircularProgress size={24}/>
          }
          <FormattedMessage id='action.save'/>
        </Button>
      </Box>
    </>
  )
}

export default injectIntl(EditPassword);
