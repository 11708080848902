import actionsHelper from 'utils/actionsHelper';

const names:any = [
  'CREATE_PROPERTY_HOLDER',
  'UPDATE_PROPERTY_HOLDER',
  'DELETE_PROPERTY_HOLDER',
  'GET_PROPERTY_HOLDER_DETAIL'
];

const  createActions:any = actionsHelper(names);

export const GET_PROPERTY_META_REQUEST: string = 'GET_PROPERTY_META_REQUEST';
export const GET_PROPERTY_META_SUCCESS: string = 'GET_PROPERTY_META_SUCCESS';
export const GET_PROPERTY_META_ERROR: string = 'GET_PROPERTY_META_ERROR';

export const GET_PROPERTY_HOLDER_LIST_REQUEST: string = 'GET_PROPERTY_HOLDER_LIST_REQUEST';
export const GET_PROPERTY_HOLDER_LIST_SUCCESS: string = 'GET_PROPERTY_HOLDER_LIST_SUCCESS';
export const GET_PROPERTY_HOLDER_LIST_ERROR: string = 'GET_PROPERTY_HOLDER_LIST_ERROR';

const getPropertyMetaRequest = (payload: any = {}) => ({
  type: GET_PROPERTY_META_REQUEST,
  payload
});

const getPropertyMetaSuccess = (payload: any) => ({
  type: GET_PROPERTY_META_SUCCESS,
  payload
});

const getPropertyMetaError = (error: any) => ({
  type: GET_PROPERTY_META_ERROR,
  error
});

const getPropertyHolderListRequest = (payload: any = {}) => ({
  type: GET_PROPERTY_HOLDER_LIST_REQUEST,
  payload
});

const getPropertyHolderListSuccess = (payload: any) => ({
  type: GET_PROPERTY_HOLDER_LIST_SUCCESS,
  payload
});

const getPropertyHolderListError = (error: any) => ({
  type: GET_PROPERTY_HOLDER_LIST_ERROR,
  error
});

export default {
  GET_PROPERTY_META_REQUEST,
  GET_PROPERTY_META_SUCCESS,
  GET_PROPERTY_META_ERROR,
  GET_PROPERTY_HOLDER_LIST_REQUEST,
  GET_PROPERTY_HOLDER_LIST_SUCCESS,
  GET_PROPERTY_HOLDER_LIST_ERROR,
  getPropertyMetaRequest,
  getPropertyMetaSuccess,
  getPropertyMetaError,
  getPropertyHolderListRequest,
  getPropertyHolderListSuccess,
  getPropertyHolderListError,
  ...createActions.types,
  ...createActions.actions
}