import actionsHelper from 'utils/actionsHelper';
const names:any = [
  'CHANGE_PASSWORD',
  'UPDATE_ME_PROFILE',
  'GET_USER_KYC',
  'UPDATE_USER_KYC',
  'CHANGE_USER_KYC_STATUS',
  'GET_BANK_ACCOUNT',
  'CREATE_BANK_ACCOUNT',
  'UPDATE_BANK_ACCOUNT',
  'UPLOAD_PRIVATE_IMAGE',
];

const  createActions:any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}