import actionsHelper from 'utils/actionsHelper';

const names:any = [
  'GET_RECORD_LIST',
  'CREATE_RECORD',
  'UPDATE_RECORD',
  'DEL_RECORD'
];

const  createActions:any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}